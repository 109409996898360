.app {
  text-align: center;
}

.header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 2%
}

.images {
  padding: 20
}


.title {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(27, 27, 27);
}

.link {
  color: #3573e6;
  text-decoration: none; 
}