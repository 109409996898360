.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(27, 27, 27);
}

.link {
  color: #3573e6;
  text-decoration: none; 
}

.imageBox {
  position: relative;
}

.imageBox .hoverImg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.imageBox:hover .hoverImg {
  display: block;
}