.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(27, 27, 27);
}

.link {
  color: #3573e6;
  text-decoration: none; 
}

.amazing {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #ffffff;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #919191;
  --control-bullet-active-color: #424242;
  --loader-bar-color: transparent;
  --loader-bar-height: 6px;
}